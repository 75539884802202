<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { cart } from '$lib/store/cart.store';
	import { mapToNode } from '$lib/utils/product';
	import { getTranslate, T } from '@tolgee/svelte';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';
	import ButtonSecondary from '../Button/ButtonSecondary.svelte';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import ProductCardHorizontalLineItem from '$lib/components/Product/ProductCardHorizontalLineItem.svelte';
	import ProductCardHorizontalProduct from '$lib/components/Product/ProductCardHorizontalProduct.svelte';
	import { resolveRoute } from '$app/paths';
	import FocusTrap from '$lib/components/Drawer/FocusTrap.svelte';

	export let lineIds: string[];
	export let expanded = false;

	$: addedLines = mapToNode($cart.data?.lines).filter((line) => lineIds.includes(line.id));
	$: crossSellStores = $cart.crossSellProducts;

	let delayedExpanded = expanded;

	$: if (expanded) {
		delayedExpanded = expanded;
	} else {
		setTimeout(() => (delayedExpanded = expanded), 300);
	}

	const { t } = getTranslate();

	$: if (addedLines.length === 0) {
		expanded = false;
	}
</script>

{#if expanded}
	<FocusTrap on:click={() => (expanded = false)} class="z-30" />
{/if}
<div
	class={classes(
		'fixed flex bg-white flex-col w-full max-w-md h-full right-0 top-0 transition overflow-y-auto transform duration-500',
		expanded ? 'z-50' : 'translate-y-full md:translate-y-0 md:translate-x-full',
		delayedExpanded ? 'z-50' : '-z-10 invisible'
	)}
>
	<div class="bg-accent-primary px-6 py-4 text-white">
		<div class="flex justify-end">
			<button on:click={() => (expanded = false)} class="i-sprenger-x-circle w-10 h-10 text-white"
			></button>
		</div>
		<div class="text-h-24 pt-16">
			<T keyName="product.addedToCart.headline" />
		</div>
	</div>
	<div class="py-8 px-4 space-y-4">
		{#each addedLines as line}
			<ProductCardHorizontalLineItem data={line} />
		{/each}
	</div>
	<hr class="mx-4" />
	<div class="text-h-24 flex-0 px-4 mt-8">
		<T keyName="product.matchingProducts" />
	</div>
	<div class="p-4 space-y-4 md:overflow-y-auto">
		{#each $crossSellStores.slice(0, 3) as store}
			{#if store?.data?.product?.product}
				<ProductCardHorizontalProduct data={store.data.product.product} />
			{/if}
		{/each}
	</div>
	<div class="sticky space-y-4 p-4">
		<a
			href={resolveRoute('/[locale]-[country]/cart', {
				locale: $page.params.locale,
				country: $page.params.country
			})}
		>
			<ButtonPrimary
				class="w-full"
				text={$t('toCart')}
				on:click={() => {
					expanded = false;
				}}
			/>
		</a>
		<ButtonSecondary class="w-full" text={$t('goOnShopping')} on:click={() => (expanded = false)} />
	</div>
</div>
