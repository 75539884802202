<script lang="ts">
	import type { ProductCardMissingInfo$result } from '$houdini';
	import ProductCardHorizontal from '$lib/components/Product/ProductCardHorizontal.svelte';
	import { parseImageUrls } from '$lib/utils/image';
	import ProductPriceRange from '$lib/components/Product/ProductPriceRange.svelte';

	export let data: NonNullable<ProductCardMissingInfo$result['product']>;

	$: imageData = parseImageUrls(data.imageUrls || data.variants.edges[0].node.imageUrls)?.[0];
</script>

<ProductCardHorizontal
	title={data.title}
	imageUrl={imageData || ''}
	rawHighlights={data.highlights}
	handle={data.handle}
	collections={data.collections}
>
	<svelte:fragment slot="price">
		<div class="font-normal text-opacity-75 text-h-16">
			<ProductPriceRange
				priceRange={data.priceRange}
				compareAtPriceRange={data.compareAtPriceRange}
			/>
		</div>
	</svelte:fragment>
</ProductCardHorizontal>
