<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	let scrollY = 0;
	let className = '';
	export { className as class };
</script>

<svelte:window bind:scrollY />
<div
	on:keypress
	role="button"
	tabindex="0"
	on:click
	class={classes('fixed z-10 w-full h-full left-0 top-0', className)}
></div>
