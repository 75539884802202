import type { ISbRichtext, StoryblokComponentType } from '@storyblok/svelte';
import type { ISbComponentType } from 'storyblok-js-client';

export type StoryblokComponentProps<E> = { blok: E };

export const getImageDimensions = (image: ImageStoryblok) => {
	try {
		if (!image?.filename) {
			return { height: 0, width: 0 };
		}

		const url = image.filename;

		return {
			// https://www.storyblok.com/faq/image-dimensions-assets-js
			height: Number(url.split('/')[5]?.split('x')[1]) || 0,
			width: Number(url.split('/')[5]?.split('x')[0]) || 0
		};
	} catch {
		return { height: 0, width: 0 };
	}
};

export interface Paragraph {
	content: Array<{
		text: string;
		type: 'text';
	}>;
	type: 'paragraph';
}
export interface RichTextContent {
	content: Array<Paragraph>;
}

export interface ShopTheLookPoint {
	desktop?: {
		x: number;
		y: number;
	};
	mobile?: {
		x: number;
		y: number;
	};
	productSKU: string;
}

export interface ShopTheLookProps extends SbBlokData {
	asset: ImageStoryblok;
	points: Array<ShopTheLookPoint>;
	className?: string;
}

/** Not needed because we don't use type-sense for this anyway */
export type DynamicComponentBlok = any;

// The content branches including the noBranding flag that indicates no branding should be shown.
export type ContentBranch = 'dog' | 'boat' | 'horse' | 'sprenger' | 'noBranding';

// The core content branch type - without noBranding flag.
export type ContentBranchCore = Exclude<ContentBranch, 'noBranding'>;

// BLOK DEFINITIONS
// interface GenericBlok {
//   _editable: string;
//   _uid: string;
//   component: StoryBlokComponentSlug;
// }

export type SbBlokKeyDataTypes = string | number | object | boolean | undefined;

export interface SbBlokData extends ISbComponentType<string> {
	[index: string]: SbBlokKeyDataTypes;
}
export interface HeroBlockProps extends SbBlokData {
	headline: string;
	title: string;
	description: string;
	bg_image: ImageStoryblok;
	floating_image: ImageStoryblok;
	button_text: string;
	button_link: LinkStoryblok;
	content_branch: ContentBranch;
	signature: ContentBranchCore;
}

export interface ImageStoryblok {
	alt: string | undefined;
	copyright: any;
	fieldtype: 'asset';
	filename: string | null;
	focus?: string;
	id: string | null;
	name: string;
	title: string | null;
}

export interface StoryblokImageOrVideoProps {
	video_id?: string;
	autoplay?: boolean;
	filename?: string | null;
	shopTheLook?: ShopTheLookProps;
	parallax?: boolean;
	className?: string;
	alt?: string | null;
	width?: number;
	height?: number;
	sizes?: string;
	srcset?: string;
	src?: string | null;
	loading?: 'lazy' | 'eager';
	priority?: boolean;
	quality?: number;
	layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
	objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
	blurDataURL?: string;
}

export interface LinkStoryblok {
	cached_url: string;
	fieldtype: 'multilink';
	id: string;
	linktype: 'url' | 'story';
	url: string;
}

export interface SizeBlok extends SbBlokData {
	image: ImageStoryblok;
	title: string;
	description: ISbRichtext;
	/** Is true by default -> storyblok sends as undefined or false */
	showPimDetails: boolean;
}

export interface ContentBlock1Props extends StoryblokComponentType<'imagePair'> {
	headline: string;
	text: string;
	image1: ImageStoryblok;
	button_text: string;
	button_link: LinkStoryblok;
	image_emphasis: 'left' | 'right';
	image2?: ImageStoryblok;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook1?: ShopTheLookProps;
	shopTheLook2?: ShopTheLookProps;
	image_1_parallax: boolean;
	image_2_parallax: boolean;
	blue_background: boolean;
}

export interface ContentBlock2Props extends SbBlokData {
	title: string;
	subtitle: string;
	description: string;
	bg_image: ImageStoryblok;
	image_parallax: boolean;
	shopTheLook?: ShopTheLookProps;
	floating_image: ImageStoryblok;
	button_text: string;
	button_link: LinkStoryblok;
	image_emphasis: 'left' | 'right';
	video_id?: string;
	autoplay?: boolean;
	desktopImageSize?: { value: number };
	blue_background: boolean;
}

export interface ContentBlock3Props extends SbBlokData {
	headline: string;
	text: string;
	subtitle: string;
	button_text: string;
	button_link: LinkStoryblok;
	text_emphasis: 'left' | 'right' | 'center';
	blue_background: boolean;
}

export interface ContentBlock4Props extends SbBlokData {
	title: string;
	subtitle: string;
	description: string;
	image: ImageStoryblok;
	image_parallax: boolean;
	content_branch: ContentBranch;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	is_text_wide?: boolean;
	blue_background: boolean;
}

export interface ContentBlock5Props extends SbBlokData {
	image_emphasis: 'left' | 'right';
	subtitle: string;
	title: string;
	description: string;
	image_left?: ImageStoryblok;
	image_left_parallax: boolean;
	image_right: ImageStoryblok;
	image_right_parallax: boolean;
	button_text: string;
	button_link: LinkStoryblok;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook1?: ShopTheLookProps;
	shopTheLook2?: ShopTheLookProps;
	blue_background: boolean;
}

export interface ContentBlock6Props extends SbBlokData {
	title: string;
	titleRich: RichTextContent;
	description: string;
	descriptionRich: ISbRichtext;
	use_richtext: boolean;
	bg_image: ImageStoryblok;
	image_parallax: boolean;
	floating_image: ImageStoryblok;
	button_text: string;
	button_link: LinkStoryblok;
	image_emphasis: 'left' | 'right';
	content_branch: ContentBranch;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	mobileProductImageSize?: { value: number };
	blue_background: boolean;
	add_padding?: boolean;
}

export interface ContentBlock7Props extends SbBlokData {
	headline: string;
	text: string;
	image: ImageStoryblok;
	image_parallax: boolean;
	product_1: string;
	product_2: string;
	id: string;
	image_emphasis: 'left' | 'right';
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	fill_image?: boolean;
	blue_background: boolean;
}

export interface ContentBlock8Props extends SbBlokData {
	title: string;
	image: ImageStoryblok;
	image_parallax: boolean;
	logo: ContentBranchCore;
	content_branch: ContentBranch;
	image_emphasis: 'left' | 'right';
	shopTheLook?: ShopTheLookProps;
}

export interface ContentBlock9Props extends SbBlokData {
	image: ImageStoryblok;
	mobile_image: ImageStoryblok;
	button_text: string;
	no_mobile_padding: boolean;
	button_link: LinkStoryblok;
}

export interface ContentBlock10Props extends SbBlokData {
	headline: string;
	title_1: string;
	title_2: string;
	title_3: string;
	link_1: LinkStoryblok;
	link_2: LinkStoryblok;
	link_3: LinkStoryblok;
	image_1: ImageStoryblok;
	image_2: ImageStoryblok;
	image_3: ImageStoryblok;
}

export interface ContentBlock11Props extends SbBlokData {
	title: string;
	description: ISbRichtext;
	image_1: ImageStoryblok;
	image_2: ImageStoryblok;
	image_left: boolean;
	content_branch: ContentBranch;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	blue_background: boolean;
}

export interface ContentBlock12Props extends SbBlokData {
	title: string;
	description: ISbRichtext;
	image_1: ImageStoryblok;
	image_2: ImageStoryblok;
	size: 'big' | 'small';
	text_emphasis: 'top' | 'bottom';
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	button_text?: string;
	button_link?: LinkStoryblok;
	blue_background: boolean;
}

export interface ContentBlock13Props extends SbBlokData {
	title: string;
	textContent: string;
	imageLeft?: boolean;
	image?: ImageStoryblok;
	image_parallax: boolean;
	content_branch: ContentBranch;
	video_id?: string;
	autoplay?: boolean;
	shopTheLook?: ShopTheLookProps;
	split_headline?: boolean;
}

export interface ContentBlock14Props extends SbBlokData {
	headline: ISbRichtext;
	title_1: string;
	text_content_1: ISbRichtext;
	image_1: ImageStoryblok;
	link_1: LinkStoryblok;
	content_branch_1: ContentBranch;
	title_2: string;
	text_content_2: ISbRichtext;
	image_2: ImageStoryblok;
	link_2: LinkStoryblok;
	content_branch_2: ContentBranch;
	title_3: string;
	text_content_3: ISbRichtext;
	image_3: ImageStoryblok;
	link_3: LinkStoryblok;
	content_branch_3: ContentBranch;
}

export interface BoxProps extends SbBlokData {
	items: DynamicComponentBlok[];
}

export interface MaterialAndCareBlockProps extends SbBlokData {
	title: string;
	description: string;
}

export interface QAndABlockProps extends SbBlokData {
	question: string;
	answer: string;
}

export interface SectionProps extends SbBlokData {
	items: DynamicComponentBlok[];
}

export interface SliderProps extends SbBlokData {
	title?: string;
	collection_slug?: string;
	products?: { productOrVariant: string }[];
	size: 'small' | 'big';
}

export type CollectionMeta = {
	title?: string;
	description?: string;
	keywords?: string;
	og_image?: ImageStoryblok;
};

export type CollectionFaq = {
	question: string;
	answer: string;
};

export interface CollectionProps extends SbBlokData {
	info: ISbRichtext;
	meta?: CollectionMeta;
	faq: CollectionFaq[];
}

export interface FaqItemProps extends SbBlokData {
	content_link: LinkStoryblok;
}

export interface YoutubeBlockProps extends SbBlokData {
	video_id: string;
	autoplay: boolean;
}

export interface MagazinTitleProps extends SbBlokData {
	title: string;
	author: string;
	date: Date;
	preview_text: string;
	preview_image: ImageStoryblok;
	category: ContentBranchCore;
}

export interface HeroSliderBlockProps extends SbBlokData {
	slides: HeroSlide[];
}

export interface HeroSlide {
	title: string;
	subtitle: string;
	call_to_action: string;
	bg_image: ImageStoryblok;
	foreground_image: ImageStoryblok;
	href: LinkStoryblok;
	content_branch: ContentBranch;
	signature: ContentBranchCore;
}

type MetaComponentType = 'title' | 'description' | 'meta_keywords' | 'should_not_index';

export type MetaBlockProps = {
	_uid: string;
	component: MetaComponentType;
} & (
	| {
			title: any;
			component: 'title';
	  }
	| {
			description: any;
			component: 'description';
	  }
	| {
			meta_keywords: any;
			component: 'meta_keywords';
	  }
	| {
			should_not_index: any;
			component: 'should_not_index';
	  }
);

export interface NewsletterBlockProps extends StoryblokComponentType<string> {
	title: string;
	description: ISbRichtext;
	button_cta: string;
	image?: ImageStoryblok;
	image_emphasis: 'left' | 'right';
}

export interface MagazinShareProps extends SbBlokData {
	image: ImageStoryblok;
	title: string;
}
